import { gql } from '@apollo/client';

export const GET_USER_TASKS = gql(`
query GetAuthorityUserTasks($authorityTypeId: ID!) {
  getAuthorityUserTasks(authorityTypeId: $authorityTypeId) {
    createActiveCycleList
    createNextCycleList
  }
}
`);

export const GET_ACTIVE_CYCLE = gql(`
query GetActiveDisclosureCycle($authorityTypeId: ID!) {
  getActiveDisclosureCycle {
    disclosureCycleId
    startDate
    endDate
    deadlineToDisclose(authorityTypeId: $authorityTypeId)
    year
    submitDueDate
    name
  }
}
`);

export const GET_ACTIVE_DISCLOSURE_CYCLE_FOR_DISCLOSER = gql(`
query GetActiveDisclosureCycleForDiscloser {
  getActiveDisclosureCycle {
      disclosureCycleId
      name
      startDate
      endDate
      submitDueDate
      year
  }
}
`);

export const GET_DISCLOSURE_CYCLE_BY_ID = gql(`
query GetDisclosureCycleById($disclosureCycleId: ID) {
  getDisclosureCycleById(disclosureCycleId: $disclosureCycleId) {
    name
    startDate
    year
    endDate
    disclosureCycleId
  }
}
`);

export const GET_LIST_FOR_CYCLE = gql(`
query GetDiscloserListForCycle($authorityTypeId: ID!, $disclosureCycleId: ID!) {
  getDiscloserListForCycle(authorityTypeId: $authorityTypeId, disclosureCycleId: $disclosureCycleId) {
    discloserListId
    title
    status
    totalOrganisationCount
    invitedOrganisationsCount
    submittable
    submitDueDate
    scoringDeadline
    isBeforeBusinessDeadline
    deadlineToDisclose
    disclosureCycle {
      year
      name
      disclosureCycleId
      startDate
      endDate
    }
    authorityType{
      authorityTypeId
    }
    totalIncompleteDiscloserListOrganisations
    maxNumberOfDisclosures
  }
}
`);

export const GET_TOP_COUNTRIES = gql(`
query TopCountries($disclosureCycleId: ID!, $authorityTypeId: ID!) {
  topCountries(disclosureCycleId: $disclosureCycleId, authorityTypeId: $authorityTypeId) {
    percentage
    countryName
    count
    countryId
    countryAlpha2
  }
}
`);

export const GET_TOP_5_COUNTRIES = gql(`
query Top5Countries($disclosureCycleId: ID!, $authorityTypeId: ID!) {
  topCountries(disclosureCycleId: $disclosureCycleId, authorityTypeId: $authorityTypeId, take:5) {
    percentage
    countryName
    count
    countryId
    countryAlpha2
  }
}
`);

export const GET_TOP_INDUSTRIES = gql(`
query TopIndustries($disclosureCycleId: ID!, $authorityTypeId: ID!) {
  topIndustries(disclosureCycleId: $disclosureCycleId, authorityTypeId: $authorityTypeId) {
    industryId
    industryName
    count
    percentage
  }
}
`);

export const GET_ALL_DISCLOSER_LISTS = gql(`
query GetAllAuthorityDiscloserLists($authorityTypeId: ID!) {
  getAllAuthorityDiscloserLists(authorityTypeId: $authorityTypeId) {
    discloserListId
    title
    status
    created_on
    updated_on
    responseRate
    blocked
    invitedOrganisationsCount
    totalOrganisationCount
    disclosureCycle {
      name
      disclosureCycleId
      startDate
      endDate
      year
      submitDueDate
      deadlineToDisclose(authorityTypeId: $authorityTypeId)
    }
    activeDates {
      endDate
      startDate
    }
  }
}
`);

export const GET_RECENT_DISCLOSER_LISTS = gql(`
query GetAllRecentDiscloserLists($authorityTypeId: ID!) {
  getAllAuthorityDiscloserLists(authorityTypeId: $authorityTypeId) {
    status
    responseRate
    discloserListId
    invitedOrganisationsCount
    totalOrganisationCount
    disclosureCycle {
      disclosureCycleId
      year
      submitDueDate
      deadlineToDisclose(authorityTypeId: $authorityTypeId)
    }
  }
}
`);

export const GET_DISCLOSER_LIST_ORGANISATION_BY_ID = gql(`
query GetDiscloserListOrganisationById($discloserListOrganisationId: ID!) {
  getDiscloserListOrganisationById(discloserListOrganisationId: $discloserListOrganisationId) {
    authorityReference
    discloserEmail
    discloserListOrganisationId
    requestsCount
    status
    response {
      responseStatus
      progress
      isPublic
    }
    previousResponse{
      responseStatus
      created_on
    }
    organisation {
      orgName
      localOrgName
      longitude
      latitude
      organisationNo
      isInvestorRequested
      marketCapitalisation
      questionnaireSector {
        name
      }
      ParentOrganisation {
        orgName
      }
      organisationId
      stateOrRegion
      organisationType {
        name
      }
      OrgCountry {
        countryName
      }
      OrgHqCountry {
        countryName
      }
      organisationType {
        name
      }
      LEI
      ISIN
      DUNS
      Ticker
      BIC
      industry {
        industryName
      }
      activityGroup {
        activityGroupName
      }
      activity {
        activityName
      }
    }
    waterTheme
    forestTheme
    disclosingOrganisation {
      orgName
    }
  }
}
`);

export const GET_DISCLOSER_LIST = gql(`
query GetDiscloserListByIdentifier($discloserListId: ID!, $industryIds: [String!], $countryIds: [String!], $status: [String!], $responseStatus: [String!], $themes: [String!], $DUNS: [String!], $take: Int, $skip: Int, $keyword: String, $createdSince: Int, $incompleteOnly: Boolean) {
  getDiscloserListByIdentifier(discloserListId: $discloserListId){
    authorityOrg {
      canAddContacts
    }
    authorityType {
      name
      authorityTypeId
    }
    maxNumberOfDisclosures
    discloserListId
    title
    updated_on
    disclosureCycleId
    status
    submitted
    submittable
    isAmendable
    totalIncompleteDiscloserListOrganisations
    mustBeSubmitted
    isBeforeBusinessDeadline
    scoringDeadline
    blocked
    totalOrganisationCount
    totalSubmittedOrgToBeAdded
    latestAdditionsCount
    discloserListOrganisations(industryIds: $industryIds, countryIds: $countryIds, status: $status, responseStatus: $responseStatus, themes: $themes, DUNS: $DUNS, take: $take, skip: $skip, keyword: $keyword, createdSince: $createdSince, incompleteOnly: $incompleteOnly){
      counts {
        all
        noResponse
        activated
        submitted
      }
      data {
        authorityReference
        discloserListOrganisationId
        requestsCount
        status
        response {
          responseStatus
          progress
          confirmIntention
          isPublic
        }
        previousResponse{
          responseStatus
          created_on
        }
        organisation {
          orgName
          organisationNo
          isInvestorRequested
          organisationId
          OrgCountry {
            countryName
          }
          DUNS
          masterId
          industry {
            industryName
          }
        }
        waterTheme
        forestTheme
        disclosingOrganisation {
          orgName
        }
      }
    }
  }
}
`);

export const GET_DISCLOSER_LATE_REQUESTS = gql(`
  query GetDiscloserLateRequests($discloserListId: ID!) {
   getDiscloserListLateRequestForCycle(discloserListId: $discloserListId) {
    totalRequests
    requests {
      discloserListOrganisationLateRequestId
      responseStatus
      organisationId
      climateTheme
      created_on
      waterTheme
      forestTheme
      organisation {
        orgName
        organisationNo
        organisationId
        DUNS
        DUNS
      }
      requestingAuthority {
        orgName
        organisationType {
          name
        }
      }
    }
  }
}
`);

export const GET_DISCLOSER_LATE_REQUEST_COUNT = gql(`
  query GetDiscloserLateRequestCount($discloserListId: ID!) {
   getDiscloserListLateRequestForCycle(discloserListId: $discloserListId) {
    totalRequests
  }
}
`);

export const GET_PROJECT_INSTANCE_ORGANISATIONS_AUTHORITY_VIEW = gql(`
query GetProjectInstanceOrganisationsAuthorityView($projectInstanceId: ID!, $onlyCopied: Boolean, $orderByField: String, $orderByDirection: String, $take: Int, $skip: Int, $keyword: String, $industryIds: [String!], $countryIds: [String!], $status: [String!], $createdSince: Int, $responseStatus: [String!]) {
  getProjectInstanceOrganisations(projectInstanceId: $projectInstanceId, onlyCopied: $onlyCopied, orderByField: $orderByField, orderByDirection: $orderByDirection, take: $take, skip: $skip, keyword: $keyword, industryIds: $industryIds, countryIds: $countryIds, status: $status, createdSince: $createdSince, responseStatus: $responseStatus) {
    counts {
      all
      noResponse
      activated
      submitted
    }
    data {
      authorityReference
        discloserListOrganisationId
        status
        response {
          responseStatus
          progress
        }
        organisation {
          orgName
          ParentOrganisation {
            orgName
          }
          organisationId
          OrgCountry {
            countryName
          }
          OrgHqCountry {
            countryName
          }
          LEI
          ISIN
          DUNS
          Ticker
          BIC
          industry {
            industryName
          }
          activityGroup {
            activityGroupName
          }
          activity {
            activityName
          }
        }
        disclosingOrganisation {
          orgName
        }
    }
  }
}
`);
export const GET_SAMPLE_ORGANISATIONS = gql(`
query GetSampleOrganisations($trackingPortfolioId: String!, $onlyCopied: Boolean, $orderByField: String, $orderByDirection: String, $skip: Int, $take: Int, $keyword: String, $industryIds: [String!], $countryIds: [String!], $status: [String!], $createdSince: Int, $responseStatus: [String!]) {
  getSampleOrganisations(trackingPortfolioId: $trackingPortfolioId, onlyCopied: $onlyCopied, orderByField: $orderByField, orderByDirection: $orderByDirection, skip: $skip, take: $take, keyword: $keyword, industryIds: $industryIds, countryIds: $countryIds, status: $status, createdSince: $createdSince, responseStatus: $responseStatus) {
     counts {
      all
      noResponse
      activated
      submitted
     }
     data {
      authorityReference
        discloserListOrganisationId
        status
        response {
          responseStatus
          progress
        }
        organisation {
          orgName
          ParentOrganisation {
            orgName
          }
          organisationId
          OrgCountry {
            countryName
          }
          OrgHqCountry {
            countryName
          }
          LEI
          ISIN
          DUNS
          Ticker
          BIC
          industry {
            industryName
          }
          activityGroup {
            activityGroupName
          }
          activity {
            activityName
          }
        }
        disclosingOrganisation {
          orgName
        }
    }
  }
}
`);

export const GET_ORGANISATION = gql(`
query GetOrganisationByIdentifier($idType: organisationIdType!, $idValue: String!) {
  getOrganisationByIdentifier(idType: $idType, idValue: $idValue) {
    organisationId
    orgName
    OrgHqCountry {
      countryName
    }
    OrgCountry {
      countryName
    }
    activity {
      activityName
    }
    activityGroup {
      activityGroupName
    }
    industry {
      industryName
    }
    ParentOrganisation {
      orgName
    }
    BIC
    Ticker
    ISIN
    LEI
    DUNS
  }
}`);

export const GET_MY_ORG_DETAILS = gql(`
query GetMyOrganisationDetails {
  getMyOrganisationDetails {
    canAddContacts
    organisationId
    DUNS
    LEI
    isEligibleToPay
    localOrgName
    orgName
    description
    website
    logoUrl
    organisationNo
    submissionLead {
      submissionLeadId
    }
    orgHqAddressLine1
    orgHqPostalCode
    orgHqCity
    orgTypeId
    OrgHqCountry {
      countryId
      countryName
      creditCardAccepted
    }
    OrgCountry {
      countryId
      countryName
      alpha2
      creditCardAccepted
    }
    activity {
      activityId
      activityName
    }
    activityGroup {
      activityGroupId
      activityGroupName
    }
    industry {
      industryId
      industryName
    }
    organisationAuthorityTypes {
      authorityType {
        authorityTypeId
        name
      }
    }
    organisationType{
      organisationTypeId
      name
    }
    organisationTags {
      tag{
        name
      }
    }
    isInvestorRequested
    isPublicAuthority
    hasNonPublicAuthoritiesRequests
    isRequestedByCDPCapitalMarkets
    isC40Requested
    ParentOrganisation {
      organisationId
      orgName
    }
    ChildOrganisations {
      organisationId
      orgName
    }
    organisationActivityClassifications {
      organisationActivityClassificationId
      industry {
        industryId
        industryName
      }
      activityGroup {
        activityGroupId
        activityGroupName
      }
      activity {
        activityId
        activityName
          questionnaireSector {
            name
          }
      }
      sortOrder
      isPrimary
      industryId
      activityGroupId
      activityId
    }
    partners {
      partnerId
      name
      link
      country {
        countryName
        alpha2
      }
    }
    firstAddPartnerUserId
  }
}
`);

export const GET_IS_INVESTOR_REQUESTED_BY_CMS = gql(`
query GetIsInvestorRequestedByCMS {
  getMyOrganisationDetails {
    isInvestorRequested
    isRequestedByCDPCapitalMarkets
  }
}
`);

export const GET_ORG_RESPONSE_BY_CYCLE_AND_QUESTIONNAIRE = gql(`
query GetOrgResponseByCycle($disclosureCycleId: ID!) {
  getOrgResponseByCycle(disclosureCycleId: $disclosureCycleId) {
    confirmIntention
    responseStatus
  }
}
`);

export const GET_ORGANISATION_PRODUCTS = gql(`
  query GetOrganisationFeeProducts($countryId: String) {
    getOrganisationFeeProducts(countryId: $countryId) {
      amount
      description
      isoCurrencyCode
      name
      productListItemId
      taxAmount
      taxPercentage
      totalAmount
    }
  }
`);

export const GET_ORGANISATION_MEMBERSHIP_PRODUCTS = gql(`
query GetOrganisationMembershipProducts($countryId: String, $authorityTypeId: String!) {
  getOrganisationMembershipProducts(countryId: $countryId, authorityTypeId: $authorityTypeId) {
     amount
      description
      isoCurrencyCode
      name
      productListItemId
      taxAmount
      taxPercentage
      totalAmount
  }
}
`);

export const SEARCH_ORGANISATION = gql(`
query SearchOrganisation($keyword: String!, $countryId: String,  $industryId: String, $idType: String, $idValue: String, $take: Int, $skip: Int, $authTypeId: String, $orgTypeId: String, $authorityOnly: Boolean) {
  searchOrganisation(keyword: $keyword, countryId: $countryId, industryId: $industryId, idType: $idType, idValue: $idValue, take: $take, skip: $skip, authTypeId: $authTypeId, orgTypeId: $orgTypeId, authorityOnly: $authorityOnly) {
    organisations {
      organisationId
      orgName
      organisationNo
      domainInvitesEnabled 
      organisationType {
        name
      }
      organisationAuthorityTypes {
        authorityTypeId
        authorityType {
          name
        }
      }
      industry {
        industryName
      }
      OrgCountry {
        countryName
      }
      ParentOrganisation {
        orgName
      }
      DUNS
      LEI
      responseStatus
    }
    totalCount
  }
}
`);

export const GET_DOWNLOAD_LINK = gql(`
query GetDiscloserListExportFileLink($discloserListId: ID!) {
  getDiscloserListExportFileLink(discloserListId: $discloserListId) {
    link
  }
}
`);

export const GET_ACTIVE_MEMBERSHIP = gql(`
  query GetActiveMembership($organisationId: String!, $authorityTypeId: String!) {
    getActiveMembership(organisationId: $organisationId, authorityTypeId: $authorityTypeId) {
      endDate
      readyForRenewal
    }
  }
`);

export const GET_COPIABLE_LISTS = gql(`
query GetCopiableDiscloserLists($authorityTypeId: ID!) {
  getCopiableDiscloserLists(authorityTypeId: $authorityTypeId) {
    discloserListId
    title
  }
}
`);

export const SEARCH_COUNTRY = gql(`
query SearchCountry($keyword: String!) {
  searchCountry(keyword: $keyword) {
    countryName
    countryId
    alpha2
    isEu
  }
}
`);

export const GET_INDUSTRIES = gql(`
query GetAllIndustries {
  getAllIndustries {
    industryId
    industryName
    activityGroups {
      industryId
      activityGroupId
      activityGroupName
      activities {
        activityId
        activityName
        activityGroup {
          activityGroupId
        }
        questionnaireSector {
          questionnaireSectorId
          name
        }
      }
    }
  }
}
`);

export const CHECK_ORGS_IN_LIST = gql(`
query CheckIfOrgsInTheList($discloserListId: ID!, $organisationIds: [ID!]!) {
  checkIfOrgsInTheList(discloserListId: $discloserListId, organisationIds: $organisationIds) {
    organisationId
    result
  }
}
`);

export const GET_IMPORT_LIST_TEMPLATE = gql(`
query GetImportListTemplate($discloserListId: ID!) {
  getImportListTemplate(discloserListId: $discloserListId) {
    link
  }
}
`);

export const IMPORT_LIST_REPORTS = gql(`
query GetImportListReports($discloserListId: ID!, $authorityTypeId: ID!) {
  getImportListReports(discloserListId: $discloserListId, authorityTypeId: $authorityTypeId) {
    created_on
    importedOrgsCount
    status
    updatedOrgsCount
    createdOrgsCount
    deletedOrgsCount
    fileError
    errorsCount
    warningsCount
    reportBlobLink
  }
}
`);

export const GET_DISCLOSER_LIST_FILTERS = gql(`
query GetDiscloserListFilterOptions($discloserListId: ID!, $industries: Boolean, $countries: Boolean, $status: Boolean, $lastDisclosed: Boolean, $responseStatus: Boolean, $themes: Boolean, $DUNS: Boolean) {
  getDiscloserListFilterOptions(discloserListId: $discloserListId, industries: $industries, countries: $countries, status: $status, lastDisclosed: $lastDisclosed, responseStatus: $responseStatus, themes: $themes, DUNS: $DUNS) {
    industries {
      industryId
      industryName
    }
    countries {
      countryId
      countryName
    }
    status
    responseStatus
    lastDisclosed
    themes
    DUNS
  }
}
`);
export const GET_PROJECT_INSTANCE_ORGANISATIONS_FILTER_OPTIONS = gql(`
query GetProjectInstanceOrganisationsFilterOptions($projectInstanceId: ID!, $countries: Boolean, $industries: Boolean, $status: Boolean, $responseStatus: Boolean) {
  getProjectInstanceOrganisationsFilterOptions(projectInstanceId: $projectInstanceId, countries: $countries, industries: $industries, status: $status, responseStatus: $responseStatus) {
    industries {
      industryId
      industryName
    }
    countries {
      countryId
      countryName
    }
    status
    responseStatus
    lastDisclosed
  }
}
`);

export const GET_SAMPLE_ORGANISATION_FILTER_OPTIONS = gql(`
query GetSampleOrganisationFilterOptions($trackingPortfolioId: ID!, $countries: Boolean!, $industries: Boolean!, $status: Boolean!, $responseStatus: Boolean!, $lastDisclosed: Boolean!) {
  getSampleOrganisationFilterOptions(trackingPortfolioId: $trackingPortfolioId, countries: $countries, industries: $industries, status: $status, responseStatus: $responseStatus, lastDisclosed: $lastDisclosed) {
    industries {
      industryId
      industryName
    }
    countries {
      countryId
      countryName
    }
    status
    responseStatus
    lastDisclosed
  }
}
`);

export const GET_TRACKING_PORTFOLIO_FILTERS = gql(`
query GetTrackingPortfolioFilterOptions($trackingPortfolioId: ID!, $countries: Boolean, $industries: Boolean, $status: Boolean, $responseStatus: Boolean) {
  getTrackingPortfolioFilterOptions(trackingPortfolioId: $trackingPortfolioId, countries: $countries, industries: $industries, status: $status, responseStatus: $responseStatus) {
    industries {
      industryName
      industryId
    }
    countries {
      countryId
      countryName
    }
    status
    responseStatus
  }
}
`);

export const GET_ORGANISATION_FILTER_OPTIONS = gql(`
query GetOrganisationFilterOptions($idTypes: Boolean, $countries: Boolean, $industries: Boolean, $authTypes: Boolean, $orgTypes: Boolean) {
  getOrganisationFilterOptions(idTypes: $idTypes, countries: $countries, industries: $industries, authTypes: $authTypes, orgTypes: $orgTypes) {
    industries {
      industryId
      industryName
    }
    countries {
      countryId
      countryName
    }
    authTypes {
      name
      authorityTypeId
    }
    orgTypes {
      organisationTypeId
      name
    }
    idTypes
  }
}
`);

export const CHECK_IF_ORG_CAN_BE_SCORED = gql(`
query CheckIfCanBeScored($organisationId: ID!, $disclosureCycleId: ID!) {
  checkIfCanBeScored(organisationId: $organisationId, disclosureCycleId: $disclosureCycleId)
}
`);

export const GET_MY_DETAILS = gql(`
query GetMyDetails($track: Boolean) {
  getMyDetails(track: $track) {
    id
    jobTitle
    givenName
    surname
    emailAddress
    organisation {
      orgName
      orgTypeId
    }
  }
}
`);

export const GET_RESPONSE_RATE_STATS = gql(`
query GetResponseRateStats($disclosureCycleId: ID!, $authorityTypeId: ID!) {
  getResponseRateStats(disclosureCycleId: $disclosureCycleId, authorityTypeId: $authorityTypeId) {
    status
    authorityOrgsCount
    totalOrgsCount
    authorityOrgsPercentage
    totalOrgsPercentage
  }
}
`);

export const GET_MY_REQUESTING_AUTHORITIES = gql(`
query MyRequests($skip: Int, $take: Int) {
  myRequests(skip: $skip, take: $take) {
    totalRequesters
    requests {
      created_on
      organisation {
        orgName
        organisationId
      }
      requestedOrganisation {
        orgName
        organisationId
      }
      disclosingOrganisation {
        orgName
        organisationId
      }
      requestingAuthorityType {
        authorityTypeId
        name
      }
      requestingAuthorityId
      requestingAuthority {
        organisationId
        orgName
        logoUrl
        contactName
        contactEmail
        organisationAuthorityTypes {
          authorityType {
            authorityTypeId
            name
          }
          authorityTypeId
        }
      }
      forestThemeRequsted
      climateThemeRequested
      waterThemeRequested
      response {
        responseStatus
        progress
      }
      discloserList{
        submittedOn
      }
    }
  }
}
`);

export const GET_MY_REQUESTING_AUTHORITIES_TOTAL_REQUESTERS = gql(`
query MyRequestsTotalRequesters($skip: Int, $take: Int) {
  myRequests(skip: $skip, take: $take) {
    totalRequesters
  }
}
`);

export const GET_LATE_REQUESTS_TOTAL_REQUESTS = gql(`
query GetLateRequestsTotalRequests($disclosureCycleId: String!) {
  getLateRequests(disclosureCycleId: $disclosureCycleId) {
    totalRequests
  }
}
`);

export const GET_ALL_TAG_COLLECTIONS = gql(`
query GetAllTagCollections {
  getAllTagCollections {
      name
      tagGroups {
          name
          tags {
              name
              isVisible
              isTagMatching
              created_on
              updated_on
          }
      }
  }
}
`);

export const GET_TAG_EXPORT_FILE_LINK = gql(`
query GetTagExportFileLink {
  getTagExportFileLink {
      link
  }
}
`);

export const GET_ALL_TRACKING_PORTFOLIOS = gql(`
query GetDiscloserListTrackingPortfolios($discloserListId: ID!) {
  getDiscloserListTrackingPortfolios(discloserListId: $discloserListId) {
    name
    description
    trackingPortfolioId
    discloserListId
    snapshot {
      activated
      noResponse
      submitted
      amended
      requestNotSent
      total
      responseRate
    }
  }
}
`);

export const GET_TRACKING_PORTFOLIO_BY_ID = gql(`
query GetTrackingPorfolioById($trackingPortfolioId: ID!, $keyword: String, $take: Int, $skip: Int, $industryIds: [String!], $countryIds: [String!], $status: [String!], $responseStatus: [String!]) {
  getTrackingPorfolioById(trackingPortfolioId: $trackingPortfolioId) {
    trackingPortfolioId
    isFromSample
    sampleId
    sample {
      name
    }
    name
    description
    snapshot {
      activated
      noResponse
      submitted
      amended
      requestNotSent
      total
      responseRate
    }
    trackingPortfolioOrganisations(keyword: $keyword, take: $take, skip: $skip, industryIds: $industryIds, countryIds: $countryIds, status: $status, responseStatus: $responseStatus) {
      count
      data {
        discloserListOrganisationId
        discloserListOrganisation {
          disclosingOrganisation {
            orgName
          }
          organisation {
            orgName
            industry {
              industryName
            }
            OrgCountry {
              countryName
            }
          }
          status
          response {
            responseStatus
            progress
          }
        }
      }
    }
  }
}
`);

export const GET_TRACKING_PORTFOLIO_EXPORT_FILE_LINK = gql(`
query GetTrackingPortfolioExportFileLink($trackingPortfolioId: ID!) {
  getTrackingPortfolioExportFileLink(trackingPortfolioId: $trackingPortfolioId) {
    link
  }
}
`);

export const GET_PROJECTS = gql(`
query GetProjects($take: Int, $skip: Int) {
  getProjects(take: $take, skip: $skip) {
    totalCount
    data {
      projectId
      name
      mainAuthorityId
      logoUrl
      created_on
      updated_on
      authorityOrganisation {
        orgName
      }
    }
  }
}
`);

export const GET_ORGANISATION_USERS = gql(`
query GetOrganisationUsers {
  getOrganisationUsers {
    id
    displayName
    emailAddress
    givenName
    surname
    roles
  }
}
`);

export const GET_AVAILABLE_ROLES = gql(`
query GetAvailableRoles {
  getAvailableRoles {
    discloserRoles {
      name
      roleId
    }
    authorityRoles {
      roleId
      name
    }
  }
}
`);

export const GET_PROJECT_BY_ID = gql(`
query GetProjectById($projectId: String!) {
  getProjectById(projectId: $projectId) {
    created_on
    updated_on
    createdBy
    updatedBy
    projectId
    name
    description
    mainAuthorityId
    logoUrl
    authorityOrganisation {
      orgName
      logoUrl
    }
    mainAuthorityType {
      name
    }
    projectTranslation {
      projectTranslationId,
      field,
      translation,
      projectId,
      language
    }
  }
}
`);

export const GET_INVITE_BY_JWT = gql(`
query GetInviteByJwt($jwt: String!) {
  getInviteByJwt(jwt: $jwt) {
    inviteId
    organisationId
    email
    domain
    invitationUrl
    token
    status
    organisation {
      logoUrl
      orgName
    }
  }
}
`);

export const GET_PROJECT_INSTANCES = gql(`
query GetProjectInstances($projectId: String!, $take: Int, $skip: Int) {
  getProjectInstances(ProjectId: $projectId, take: $take, skip: $skip) {
    totalCount
    data {
      created_on
      updated_on
      createdBy
      updatedBy
      projectInstanceId
      name
      projectId
      disclosureCycleId
      description
      projectManagerId
      projectInstanceUrl
      startDate
      endDate
      isPublished
      isPublic
      subAuthorityId
      projectInstanceType
      projectManager {
        displayName
      }
      tags {
        tagGroup {
          tags {
            name
          }
        }
      }
      authorityTypes {
        name
      }
      subAuthority {
        orgName
      }
      project {
        name
      }
    }
}
}
`);

export const GET_NEXT_DISCLOSURE_CYCLE = gql(`
query GetNextDisclosureCycle($authorityTypeId: ID!) {
  getNextDisclosureCycle {
    deadlineToDisclose(authorityTypeId: $authorityTypeId)
    submitDueDate
    disclosureCycleId
    year
    name
    startDate
    endDate
    cycleMargin
  }
}
`);

export const GET_FUTURE_DISCLOSURE_CYCLES = gql(`
query GetFutureDisclosureCycles {
  getFutureDisclosureCycles {
    disclosureCycleId
    name
    year
  }
}
`);

export const GET_CDP_ADMIN_USERS = gql(`
query GetCDPAdminUsers {
  getCDPAdminUsers {
    displayName
    id
  }
}
`);

export const GET_TAGS = gql(`
query GetAllTags($keyword: String!) {
  getAllTags(keyword: $keyword) {
    tagId
    name
  }
}
`);

export const GET_AUTHORITY_TYPES = gql(`
query GetAuthorityTypes {
  getAuthorityTypes {
    authorityTypeId
    name
  }
}
`);

export const GET_PROJECT_INSTANCE_BY_ID = gql(`
query GetProjectInstanceById($projectInstanceId: String!) {
  getProjectInstanceById(projectInstanceId: $projectInstanceId) {
    isPublished
    projectManager {
      id
      displayName
    }
    startDate
    endDate
    projectInstanceType
    projectInstanceUrl
    isPublic
    name
    subAuthority {
      orgName
      logoUrl
    }
    project {
      name
      logoUrl
      description
      authorityOrganisation {
        orgName
        logoUrl
      }
      mainAuthorityType {
        name
      }
    }
    description
    projectInstanceUrl
    tags {
      name
      tagId
   }
   disclosureCycle {
    name
    disclosureCycleId
  }
   authorityTypes {
      authorityTypeId
      name
    }
   discloserListOrganisations {
      data {
        discloserListOrganisationId
        organisation {
          organisationId
          orgName
          industry {
            industryName
          }
          OrgCountry {
            countryName
          }
        }
        disclosingOrganisation {
          orgName
        }
        response {
          responseStatus
          progress
        }
        status
      }
    }
   projectInstanceTranslations {
    language,
    field,
    translation,
    projectInstanceId,
    projectInstanceTranslationId
    }
  }
}
`);

export const GET_PROJECT_INSTANCES_BY_AUTHORITY = gql(`
query GetProjectInstancesByAuthority($authorityTypeId: String!) {
  getProjectInstancesByAuthority(authorityTypeId: $authorityTypeId) {
    owned {
      project {
        logoUrl
        description
        authorityOrganisation {
          orgName
          logoUrl
        }
      }
      subAuthority {
        orgName
        logoUrl
      }
      projectInstanceType
      startDate
      endDate
      projectInstanceUrl
      description
      authorityTypes {
        name
      }
      isPublic
      projectInstanceId
      name
    }
    viewOnly {
      project {
        logoUrl
        description
        authorityOrganisation {
          orgName
          logoUrl
        }
      }
      subAuthority {
        orgName
        logoUrl
      }
      projectInstanceType
      projectInstanceUrl
      startDate
      endDate
      description
      authorityTypes {
        name
      }
      isPublic
      projectInstanceId
      name
    }
  }
}
`);

export const GET_SUSCRIBED_PROJECT_INSTANCES = gql(`
query GetSubscribedProjectInstances {
  getSubscribedProjectInstances {
    owned {
      count
      data {
        projectInstanceId
        projectInstance{
          name
          projectInstanceType
        }
        snapshot {
          activated
          noResponse
          submitted
          amended
          requestNotSent
          total
          responseRate
        }
        totalCountOfOrganisations
        countOfAcceptedOrganisations
        subscriptionType
      }
    }
    viewOnly {
      count
      data {
        projectInstanceId
        projectInstance{
          name
          projectInstanceType
        }
        snapshot {
          activated
          noResponse
          submitted
          amended
          requestNotSent
          total
          responseRate
        }
        totalCountOfOrganisations
        countOfAcceptedOrganisations
        subscriptionType
      }
    }
  }
}
`);

export const GET_PROJECT_INSTANCE_ORGANISATIONS_ADMIN_VIEW = gql(`
query GetProjectInstanceOrganisations($projectInstanceId: ID!, $skip: Int, $take: Int, $orderByDirection: String, $orderByField: String, $onlyCopied: Boolean, $keyword: String, $industryIds: [String!], $countryIds: [String!], $status: [String!], $createdSince: Int, $responseStatus: [String!]) {
  getProjectInstanceOrganisations(projectInstanceId: $projectInstanceId, skip: $skip, take: $take, orderByDirection: $orderByDirection, orderByField: $orderByField, onlyCopied: $onlyCopied, keyword: $keyword, industryIds: $industryIds, countryIds: $countryIds, status: $status, createdSince: $createdSince, responseStatus: $responseStatus) {
    counts {
      all
      noResponse
      activated
      submitted
    }
    data {
        disclosingOrganisationId
        organisation {
          orgName
          organisationNo
          OrgCountry {
            countryName
          }
          industry {
            industryName
          }
        }
        requestingAuthority {
          orgName
        }
        questionnaire {
          name
        }
        status
        projectAffiliation(projectInstanceId: $projectInstanceId) {
          relationshipType
        }
      }
    }
  }
`);

export const GET_IMPORTED_PROJECT_INSTANCE_ORGANISATIONS_ADMIN_VIEW = gql(`
query GetImportedProjectInstanceOrganisations($projectInstanceId: ID!, $take: Int, $skip: Int) {
  getImportedProjectInstanceOrganisations(projectInstanceId: $projectInstanceId, take: $take, skip: $skip) {
    count
    data {
      importedProjectInstanceOrganisationId
      projectInstanceId
      organisationId
      status
      updatedBy
      createdBy
      created_on
      updated_on
      isDeleted
      organisation {
        orgName
        organisationNo
      }
    }
  }
}
`);

export const GET_PROJECT_INSTANCE_RESPONSE_RATE = gql(`
query GetProjectInstanceSnapshot($projectInstanceId: String!) {
    getProjectInstanceSnapshot(projectInstanceId: $projectInstanceId) {
      name
      count
      percentage
    }
  }
`);

export const GET_PROJECT_INSTANCE_OPT_IN_RATE = gql(`
query GetResponseRateOptInProjects($projectInstanceId: String!) {
    getResponseRateOptInProjects(ProjectInstanceId: $projectInstanceId) {
      percentage
      name
      count
    }
  }
`);

export const GET_ORGANISATION_DOMAIN_INVITE_DETAILS = gql(`
  query GetDomainInviteLinksForOrganisation($discloserListId: String!) {
    getDomainInviteLinksForOrganisation(discloserListId: $discloserListId) {
      status                 
      fileLocation  
    }
  }
`);

export const GET_SAMPLE = gql(`
query GetSample($sampleId: String!) {
  getSample(sampleId: $sampleId) {
    trackingPortfolioId
    discloserListId
    authorityOrgId
    name
    description
    isSample
    isPublished
    authorityTypes {
      name
      authorityTypeId
    }
    snapshot {
      activated
      noResponse
      submitted
      amended
      requestNotSent
      total
      responseRate
    }
    authorityType {
      name
    }
  }
}
`);

export const GET_QUESTIONNAIRE_SETUP = gql(`
query GetQuestionnaireSetup($questionnaireSetupId: String!) {
    getQuestionnaireSetup(questionnaireSetupId: $questionnaireSetupId) {
        annualRevenueFrom
        annualRevenue
        numberOfFTE
        recommendedPathway
        selectedPathway
        elegiblePathway
        forestSelfAssessment
        forestSelfIdentification
        waterSelfAssessment
        waterSelfIdentification
        organisation {
          isInvestorRequested
          isPublicAuthority
          hasNonPublicAuthoritiesRequests
          organisationType {
            organisationTypeId
          }
          isRequestedByCDPCapitalMarkets
        }
        organisationActivityClassifications {
          organisationActivityClassificationId
          industry {
            industryId
            industryName
          }
          activityGroup {
            activityGroupId
            activityGroupName
          }
          activity {
            activityId
            activityName
              questionnaireSector {
                name
              }
          }
        sortOrder
        isPrimary
        industryId
        activityGroupId
        activityId
        }
        activityRevenuePercentages {
          activityRevenuePercentageId
          percentage
          themeByWaterImpactMethodology
          themeByForestImpactMethodology
          activity {
            waterImpact
            forestImpact
            activityName
          }
          organisationActivityClassificationId
          organisationActivityClassification {
            organisationActivityClassificationId
            industry {
              industryId
              industryName
            }
            activityGroup {
              activityGroupId
              activityGroupName
            }
            activity {
              activityId
              activityName
                questionnaireSector {
                  name
                }
            }
            sortOrder
            isPrimary
          }
        }
        waterAuthorityRequested
        forestAuthorityRequested
        forestIntentToSubmit
        waterIntentToSubmit
        updated_on
        conductMiningProjects
        jurisdictionPopulation
        perCapitaEmissions
        humanDevelopmentIndex
        citiesRecommendedPathway
        citiesSelectedPathway
        hasPreviousACSData
        isReset
        isSubmitted
        lastSubmittedOn
        cdpThemeAssessments {
          cdpThemeAssessmentId
          forestAssessment
          waterAssessment
        }
        questionnaireSetupSectorAllocations {
          questionnaireSectorId
          questionnaireSector {
            name
          }
        }
    }
  }
`);

export const GET_QUESTIONNAIRE_SETUP_BY_ORG_ID = gql(`
query GetQuestionnaireSetupByOrgId($disclosureCycleId: String!) {
  getQuestionnaireSetupByOrgId(disclosureCycleId: $disclosureCycleId) {
        questionnaireSetupId
        isSubmitted
        lastSubmittedOn
        isReset
    }}`);

export const GET_PHASES_INFORMATION = gql(`
query GetPhasesInformation($authorityTypeId: ID!) {
  getPhasesInformation(authorityTypeId: $authorityTypeId) {
    endDate
    phaseName
    startDate
  }
}
`);

export const GET_SAMPLES_BY_AUTHORITY = gql(`
query GetCycleSamplesByAuthorityTypeId($authorityType: String!) {
    getCycleSamplesByAuthorityTypeId(authorityType: $authorityType) {
      count
      data {
        authorityOrgId
        createdBy
        created_on
        description
        discloserListId
        isPublished
        isSample
        name
        snapshot {
          activated
          noResponse
          submitted
          amended
          requestNotSent
          total
          responseRate
        }
        trackingPortfolioId
        updatedBy
        updated_on
      }
    }
  }
`);

export const GET_TOP_COUNTRIES_FOR_SAMPLE = gql(`
query GetTopCountriesForSample($trackingPortfolioId: ID!) {
  getTopCountriesForSample(trackingPortfolioId: $trackingPortfolioId) {
    percentage
    count
    countryAlpha2
    countryName
    countryId
  }
}
`);

export const GET_TOP_INDUSTRIES_FOR_SAMPLE = gql(`
query GetTopIndustriesForSample($trackingPortfolioId: ID!) {
  getTopIndustriesForSample(trackingPortfolioId: $trackingPortfolioId) {
    industryId
    industryName
    count
    percentage
  }
}
`);

export const IS_CDP_AUTHORITY = gql(`
query IsSampleCDPAuthority {
    isSampleCDPAuthority
}
`);

export const GET_EXTENDED_ORG_SEARCH = gql(`
query ExtendedOrgSearch($searchTerm: String!, $countryId: String!, $address: String, $city: String, $postalCode: String) {
  extendedOrgSearch(searchTerm: $searchTerm, countryId: $countryId, address: $address, city: $city, postalCode: $postalCode) {
    orgName
    DUNS
    orgCountryName
    parentOrgName
    alpha2
    industry
    registeredAddress
    city
  }
}
`);

export const GET_TODOS = gql(`
query GetTodos($authorityTypeId: ID!) {
  getTodos(authorityTypeId: $authorityTypeId) {
    deadline
    name
    status
  }
}
`);

export const GET_ORGANISATION_BULK_UPLOAD_TEMPLATE = gql(`
query GetOrganisationBulkUploadTemplate {
  getOrganisationBulkUploadTemplate {
    link
  }
}
`);

export const GET_SAMPLE_RESPONSE_RATE = gql(`
query GetSampleResponseRate($trackingPortfolioId: ID!) {
  getSampleResponseRate(trackingPortfolioId: $trackingPortfolioId) {
    status
    count
    percentage
  }
}
`);

export const GET_ORGANISATION_ACCOUNT_MANAGER = gql(`
query GetOrganisationAccountManager($authorityTypeId: ID) {
  getOrganisationAccountManager(authorityTypeId: $authorityTypeId) {
    firstName
    lastName
    emailAddress
  }
}
`);

export const GET_ORGANISATION_BULK_UPDATE_PROGRESS = gql(`
query OrganisationBulkUpdateProgress {
  organisationBulkUpdateProgress {
    created_on
    updated_on
    createdBy
    updatedBy
    organisationBulkUpdateProgressId
    errorsCount
    fileError
    reportBlobLink
    status
  }
}
`);

export const GET_COMMODITIES = gql(`
query GetCommodities($questionnaireSetupId: String!) {
  getCommodities(questionnaireSetupId: $questionnaireSetupId) {
    commodityId
    name
    questionnaireSetupCommodities {
      questionnaireSetupCommodityId
      isDisclosing
      hasProduced
      questionnaireSetup {
        updated_on
      }
    }
  }
}
`);

export const GET_ORGANISATION_AUTHORITY_TYPES = gql(`
query GetOrganisationAuthorityTypes($organisationId: String!) {
  getOrganisationAuthorityTypes(organisationId: $organisationId) {
    authorityTypeId
    name
  }
}
`);

export const GET_NEXT_DISCLOSURE_CYCLE_FOR_DISCLOSER = gql(`
query GetNextDisclosureCycleForDiscloser {
  getNextDisclosureCycle {
    disclosureCycleId
    submitDueDate
    year
    name
    startDate
    endDate
  }
}
`);

export const GET_ORGANISATION_INVOICE = gql(`
query GetOrganisationInvoice($status: [String!]!, $disclosureCycleId: String!) {
  getOrganisationInvoice(status: $status, disclosureCycleId: $disclosureCycleId) {
    invoiceId
    invoiceType
    invoiceNumber
    orderId
    organisationId
    status
    totalInvoiceAmount
    invoiceTaxPercentage
    totalTaxAmount
    billingEmailAddress
    billingAddressLine1
    billingAddressCity
    billingAddressStateProvince
    billingAddressPostalCode
    billingFullName
    billingFirstName
    billingLastName
    billingCompanyName
    purchaseOrderNumber
    accountInternalReference
    billingEuVatNo
    country {
      countryName
      salesRegion
      countryId
      alpha2
      isEu
    }
    organisation {
      orgName
    }
    invoiceProductItems {
      productListItemId
      amount
      name
      isoCurrencyCode
      taxPercentage
      taxAmount
      totalAmount
    }
  }
}
`);

export const GET_MEMBERSHIP_INVOICE = gql(`
  query GetMembershipInvoice($status: [String!]!, $authorityTypeId: String!) {
    getMembershipInvoice(status: $status, authorityTypeId: $authorityTypeId) {
      invoiceId
      invoiceType
      invoiceNumber
      orderId
      organisationId
      tcAccepted
      status
      totalInvoiceAmount
      invoiceTaxPercentage
      totalTaxAmount
      billingEmailAddress
      billingAddressLine1
      billingAddressCity
      billingAddressStateProvince
      billingAddressPostalCode
      billingFullName
      billingFirstName
      billingLastName
      billingCompanyName
      purchaseOrderNumber
      accountInternalReference
      billingEuVatNo
      country {
        countryName
        salesRegion
        countryId
        alpha2
        isEu
      }
      organisation {
        orgName
      }
      invoiceProductItems {
        productListItemId
        amount
        name
        isoCurrencyCode
        taxPercentage
        taxAmount
        totalAmount
      }
    }
  }
  `);

export const GET_EXISTING_PROJECT_REQUESTS_NUMBER = gql(`
query GetExistingProjectRequestsNumber {
  getExistingProjectRequests {
    totalRequests
  }
}
`);

export const GET_EXISTING_PROJECT_REQUESTS = gql(`
query GetExistingProjectRequests {
  getExistingProjectRequests {
    totalRequests
    projectInstanceOrganisations {
      relationshipType
      projectInstance {
        projectInstanceType
        projectInstanceUrl
        project {
          created_on
          updated_on
          createdBy
          updatedBy
          projectId
          name
          description
          logoUrl
          authorityOrganisation {
            logoUrl
            orgName
          }
        }
        projectInstanceTranslations {
          language
          field
          translation
        }
      }
    }
  }
}
`);

export const GET_OPTIONAL_PROJECT_REQUESTS = gql(`
query GetOptionalProjectRequests($take: Int, $skip: Int) {
  getOptionalProjectRequests(take: $take, skip: $skip) {
    totalRequests
    importedProjectInstanceOrganisations {
      status
      importedProjectInstanceOrganisationId
      projectInstance {
        project {
          created_on
          updated_on
          createdBy
          updatedBy
          projectId
          name
          description
          logoUrl
          authorityOrganisation {
            logoUrl
            orgName
          }
        }
        projectInstanceTranslations {
          language
          field
          translation
        }
      }
    }
  }
}
`);

export const GET_SUBSIDIARY_REQUESTS = gql(`
query GetSubsidiaryRequests($take: Int, $skip: Int){
  mySubsidiaryRequests(take: $take, skip: $skip) {
  requests {
    organisationId
    status
    organisation {
      orgName
      logoUrl
    }
    climateThemeRequested
    waterThemeRequested
    forestThemeRequested
  }
  totalRequesters
}
}

`);

export const GET_DISCLOSER_LIST_STATUS = gql(`
query GetDiscloserListStatus($discloserListId: ID!) {
  getDiscloserListByIdentifier(discloserListId: $discloserListId){
    status
  }
}
`);

export const GET_SUBSIDIARY_REQUEST_DETAILS = gql(`
query GetSubsidiaryRequestDetails($organisationId: String!, $disclosureCycleId: String!) {
  getSubsidiaryRequestDetails(organisationId: $organisationId, disclosureCycleId: $disclosureCycleId) {
    organisationId
    climateThemeRequested
    forestThemeRequested
    waterThemeRequested
    isInvestorRequested
    numberOfRequests
    requestorsAuthorityTypes {
      name
    }
    responseInProgressCount
  }
}
`);

export const GET_DISCLOSING_ORGANISATION = gql(`
query GetDisclosingOrganisation($requestedOrgId: ID!) {
  getDisclosingOrganisation(requestedOrgId: $requestedOrgId) {
    DUNS
    orgName
    organisationId
  }
}
`);

export const GET_REQUESTERS = gql(`
query GetRequesters($requestedOrgId: ID!) {
  getRequesters(requestedOrgId: $requestedOrgId) {
    status
    title
    authorityOrg {
      orgName
      organisationId
      organisationNo
    }
  }
}
`);

export const GET_ACTIVE_REQUESTERS = gql(`
query GetActiveRequesters($requestedOrgId: ID!) {
  getActiveRequesters(requestedOrgId: $requestedOrgId) {
    status
    title
  }
}
`);

export const GET_RESPONSE_BY_ORGANISATION_ID = gql(`
query GetResponseByOrganisationId($organisationId: String!) {
  getResponseByOrganisationId(organisationId: $organisationId) {
    responseId
    organisationId
    extensionWeeks
    extensionReason
  }
}
`);

export const GET_MY_ORG_COUNTRY = gql(`
query GetMyOrgCountry {
  getMyOrganisationDetails {
    OrgCountry {
      countryId
      countryName
      creditCardAccepted
    }
  }
}
`);

export const GET_DELEGATED_REQUESTS = gql(`
  query GetDelegatedRequests($disclosureCycleId: String!, $take: Int, $skip: Int) {
    getDelegatedRequests(disclosureCycleId: $disclosureCycleId, take: $take, skip: $skip) {
      requests {
      organisation {
        orgName
        logoUrl
      }
      requestingAuthority {
        orgName
        logoUrl
      }
      climateTheme
      forestTheme
      waterTheme
      delegatedOn
      created_on
      requestingAuthorityType {
        name
      }
    }
    totalRequests
    }
  }
`);

export const GET_LATE_REQUESTS = gql(`
query GetLateRequests($disclosureCycleId: String!) {
 getLateRequests(disclosureCycleId: $disclosureCycleId) {
  totalRequests
  requests {
    discloserListOrganisationLateRequestId
    responseStatus
    organisationId
    climateTheme
    created_on
    waterTheme
    forestTheme
    organisation {
      orgName
      organisationId
    }
    requestingAuthority {
      orgName
      organisationType {
        name
      }
    }
  }
}
}
`);

export const CHECK_IF_ORGS_IN_PROJECT_INSTANCE = gql(`
query CheckIfOrgsInTheProject($projectInstanceId: ID!, $discloserListOrganisationIds: [ID!]!) {
  checkIfOrgsInTheProject(projectInstanceId: $projectInstanceId, discloserListOrganisationIds: $discloserListOrganisationIds) {
    result
    discloserListOrganisation {
      discloserListOrganisationId
      organisationId
    }
  }
}
`);

import { enqueueSnackbar } from 'notistack';
import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import i18next from '../i18.config';

export const formatErrors = (error: ApolloError): string[] => {
  const messages: string[] = [];

  if (error?.graphQLErrors?.length > 0) {
    // validation, authorisation issue
    error.graphQLErrors.map(e => {
      if (e.extensions.error === 'RequiredParameterError') {
        messages.push(i18next.t('common.errors.requiredParameter', { param: e.message }));
        return;
      }

      const originalErrorMessage = (e.extensions?.originalError as { message?: string[] })?.message;

      if (Array.isArray(originalErrorMessage)) {
        messages.push(originalErrorMessage.join(', '));
        return;
      }

      messages.push(e.message || i18next.t('common.errors.graphQLError'));
    });
  }

  if (error.networkError) {
    // timeout, client connectivity issue
    messages.push(i18next.t('common.errors.networkError'));
  }

  if (messages.length === 0) {
    // fallback
    messages.push(i18next.t('common.error'));
  }

  return messages;
};

// TODO: [wnguyen] abstract this to default behaviour on apollo client
export const onApolloError = (error: ApolloError): void => {
  Sentry.captureException(error);
  formatErrors(error).forEach(m => enqueueSnackbar(m, { variant: 'error' }));
};
